




























































import { Component, Prop, Mixins } from 'vue-property-decorator'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import axios from 'axios'
import snakeCaseKeys from 'snakecase-keys'
import camelCaseKeys from 'camelcase-keys'
import { mask } from 'vue-the-mask'

import NotifyOnFailed from '@/partials/forms/NotifyOnFailed.vue'
import ValidatorConfigMixin from '@/mixins/validatorConfigMixin'
import FlashMessageMixin from '@/mixins/flashMessageMixin'
import PasswordConfirmField from '@/partials/forms/PasswordConfirmField.vue'
import RichTextEditor from '@/partials/forms/components/RichTextEditor.vue'

@Component({
  directives: { mask },
  components: {
    RichTextEditor,
    NotifyOnFailed,
    PasswordConfirmField,
    ValidationProvider,
    ValidationObserver
  }
})
export default class StudyCriterionForm extends Mixins(ValidatorConfigMixin, FlashMessageMixin) {
  @Prop({ required: true, type: String }) title!: string
  @Prop({ required: true, type: String }) type!: 'create' | 'edit'
  @Prop({ required: true, type: String }) eventId!: string
  @Prop({ required: true, type: String }) reviewTypeId!: string
  @Prop({ type: String }) studyCriterionId!: string

  done = true
  form: Record<string, any> = {
    title: '',
    instruction: '',
    eventId: '',
    eventStudyReviewTypeId: '',
    weight: 1,
    eliminatory: false,
    type: 'number',
    order: 999,
    confirm: '',
    errors: {
      title: '',
      body: '',
      eventId: '',
      eventStudyReviewTypeId: '',
      weight: '',
      eliminatory: '',
      type: '',
      confirm: ''
    }
  }

  created () {
    this.form.eventId = this.eventId
    this.form.eventStudyReviewTypeId = this.reviewTypeId

    if (process.env.NODE_ENV === 'development' && this.type !== 'edit') {
      Object.assign(this.form, {
        title: 'Criterio ABC',
        instruction: `
          <h1>Título Teste</h1>
          <p>Esse é um paragrafo de teste</p>
          <ul>
            <li><strong>Lista 1</strong></li>
            <li><s>Lista 2</s></li>
            <li>Lista 3</li>
          </ul>
          <blockquote>Isso é uma citação</blockquote>
        `
      })
    }

    if (this.type === 'create') {
      axios.get(`/event/${this.eventId}/study-review-type/${this.reviewTypeId}/criteria`)
        .then(response => {
          this.form.order = response.data.data.pop()?.order + 1
        })
    } else if (this.type === 'edit') {
      axios.get(`/event/${this.eventId}/study-review-type/${this.reviewTypeId}/criteria/${this.studyCriterionId}`)
        .then(response => {
          const studyRule = camelCaseKeys(response.data.data, { deep: true })
          Object.keys(studyRule).forEach((key: string) => {
            this.form[key] = key in this.form ? studyRule[key] : this.form[key]
          })
        })
    }
  }

  submit () {
    this.done = false
    const verb = this.type === 'edit' ? 'put' : 'post'
    const route = this.type === 'edit'
      ? `/event/${this.eventId}/study-review-type/${this.reviewTypeId}/criteria/${this.studyCriterionId}`
      : `/event/${this.eventId}/study-review-type/${this.reviewTypeId}/criteria`

    axios[verb](route, snakeCaseKeys(this.form))
      .then(() => {
        this.redirectOnSuccess(this.form.title)
      })
      .catch(err => this.handleSubmitError(err))
      .finally(() => { this.done = true })
  }

  redirectOnSuccess (entityName: string) {
    const successVerb = this.type === 'edit' ? 'editado' : 'cadastrado'

    const flashMessage = {
      message: `O Critério ${entityName} foi ${successVerb} com sucesso.`,
      kind: 'success'
    }

    this.$router.push({ name: 'EventStudyReviewTypeEdit', params: { eventId: this.eventId, reviewTypeId: this.reviewTypeId } }, () => this.setFlashMessage(flashMessage))
  }

  handleSubmitError (err: any) {
    const response = err.response

    const errors = camelCaseKeys(response.data.errors, { deep: true })
    if (errors) {
      Object.entries(errors).map(([key, value]: [string, any]) => {
        this.form.errors[key] = value[0]
      })
    }
  }

  resetErrors () {
    this.form.errors = {
      title: '',
      body: '',
      eventId: '',
      weight: '',
      eliminatory: '',
      type: '',
      confirm: ''
    }
  }
}

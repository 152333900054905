import { render, staticRenderFns } from "./StudyCriterionForm.vue?vue&type=template&id=17725c34&scoped=true&"
import script from "./StudyCriterionForm.vue?vue&type=script&lang=ts&"
export * from "./StudyCriterionForm.vue?vue&type=script&lang=ts&"
import style0 from "./StudyCriterionForm.vue?vue&type=style&index=0&id=17725c34&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "17725c34",
  null
  
)

export default component.exports